export function createRipple(event: MouseEvent): void {
  const button = <HTMLButtonElement>event.currentTarget;

  const circle = document.createElement('span');
  const diameter = Math.max(button?.clientWidth, button?.clientHeight);
  const radius = diameter / 2;

  const offset = button.getBoundingClientRect();

  circle.style.width = circle.style.height = diameter + 'px';
  circle.style.left = event.clientX - offset.left - radius + 'px';
  circle.style.top = event.clientY - offset.top - radius + 'px';
  circle.classList.add('ripple');

  const ripple = button.getElementsByClassName('ripple')[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}
