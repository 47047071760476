import { nodes } from './navigationNodes';
import { ComponentStates } from '../../models/componentStates';
import { ActiveState } from '../../models/activeState';
import { NavigationState } from '../../models/navigationState';
import { createRipple } from '../button.ripple';

class SiteSearch {
  public body: HTMLElement;

  constructor() {
    this.body = document.body;
  }

  public create(): void {
    nodes.searchMenu = document.querySelector('.site-search') as HTMLElement;

    const buttons: HTMLElement[] = [...(document.querySelectorAll('.site-search .btn') as NodeListOf<HTMLElement>)];
    buttons.forEach((elm) => {
      elm.addEventListener('click', createRipple);
    });
  }

  public action(): void {
    if (this.body?.dataset.open === 'on') {
      this.close();
      this.body.dataset.menu = '';
      this.body.dataset.open = ActiveState.OFF;
    } else {
      this.open();
      this.body.dataset.menu = NavigationState.SEARCH;
      nodes.siteHeader?.classList.add(ComponentStates.STICKY, ComponentStates.TRANSITION, ComponentStates.VISIBLE);
      this.body.dataset.open = ActiveState.ON;
    }
  }

  public open(): void {
    nodes.searchMenu?.classList.add(ComponentStates.OPEN);
  }

  public close(): void {
    nodes.searchMenu?.classList.remove(ComponentStates.OPEN);
  }

}

export const siteSearch = new SiteSearch();