import { DebouncedFunc, debounce } from 'lodash';
import { nodes } from './navigationNodes';
import { navActions } from './navigationActions';
import { siteSearch } from './navigationSearch';
import { navMobile } from './navigationMobile';
import { ComponentStates } from '../../models/componentStates';
import { ActiveState } from '../../models/activeState';
import { NavigationEvents } from './navigationEvents';
import { viewport } from '../includeMedia';

export class Navigation {
  public body: HTMLElement;
  public offsetTop!: number;
  public pastPoint: number = 0;
  public showHideOffset: number = 30;
  
  private checkStickyWatcher: DebouncedFunc<VoidFunc> = debounce(
    this.checkSticky,
    0
  ).bind(this);

  private resizeHandler: DebouncedFunc<VoidFunc> = debounce(
    this.resetNav,
    50
  ).bind(this);

  constructor(el: HTMLElement) {
    nodes.siteHeader = el;
    this.offsetTop = nodes.siteHeader.offsetHeight;
    this.body = document.body;

    this.firstRun();
    this.checkSticky();

    // Bind events.
    window.addEventListener('scroll', this.checkStickyWatcher);
    window.addEventListener('resize', () => {
      if (viewport.greaterThan('laptop')) this.resizeHandler();
    }, false);
    window.addEventListener(
      NavigationEvents.NAVIGATION_FILL,
      this.bannerExit.bind(this)
    );
    window.addEventListener(
      NavigationEvents.NAVIGATION_TRANSPARENT,
      this.bannerEnter.bind(this)
    );

    // Start modules.
    navActions.create();
    siteSearch.create();
  }

  public firstRun(): void {
    if (window.pageYOffset <= this.offsetTop) {
      nodes.siteHeader?.classList.add(ComponentStates.VISIBLE);
      document.body.dataset.headerState = ComponentStates.VISIBLE;
    }
    setTimeout(() => {
      nodes.siteHeader?.classList.add(ComponentStates.LOADED);
    }, 400);
  }

  public checkSticky(): void {

    const scrollDifference: number = Math.abs(window.pageYOffset - this.pastPoint);
    if (scrollDifference < this.showHideOffset) return;

    if (window.pageYOffset > this.offsetTop) {
      if (this.pastPoint > window.pageYOffset) {
        // adding the state to the body to allow children mods
        document.body.dataset.headerState = ComponentStates.VISIBLE;
        nodes.siteHeader?.classList.add(ComponentStates.TRANSITION);
        nodes.siteHeader?.classList.add(ComponentStates.VISIBLE);
      } else {
        document.body.dataset.headerState = '';
        document.body.dataset.headerSticky = ComponentStates.STICKY;
        nodes.siteHeader?.classList.add(ComponentStates.STICKY);
        nodes.siteHeader?.classList.remove(
          ComponentStates.VISIBLE,
          ComponentStates.ACTIVE
        );
        setTimeout(() => {
          nodes.navigationLinkHolder?.forEach((item) =>
            item.classList.remove(ComponentStates.OPEN)
          );
        });
      }
    }

    if (window.pageYOffset < 1) {
      document.body.dataset.headerSticky = '';
      nodes.siteHeader?.classList.remove(ComponentStates.STICKY);
      nodes.siteHeader?.classList.remove(ComponentStates.TRANSITION);
    }

    this.pastPoint = window.pageYOffset;
  }

  public resetNav(): void {
    if (this.body.dataset.open === ActiveState.ON) {
      navMobile.close();
      siteSearch.close();
      this.body.dataset.menu = '';
      this.body.dataset.open = ActiveState.OFF;
      nodes.siteHeader?.classList.add(
        ComponentStates.STICKY,
        ComponentStates.TRANSITION,
        ComponentStates.VISIBLE
      );
    }
  }

  private bannerExit(): void {
    nodes.siteHeader?.classList.add('fill-color');
  }
  private bannerEnter(): void {
    nodes.siteHeader?.classList.remove('fill-color');
  }
}
