import template from 'lodash/template';
import { nodes } from './navigationNodes';
import { ComponentStates } from '../../models/componentStates';
import { ActiveState } from '../../models/activeState';
import { NavigationState } from '../../models/navigationState';

const menuTpl = require('html-loader!./navigationMobile.tpl.html').default;

class MobileMenu {
  public busy: boolean | undefined;
  public created: boolean | undefined;
  public body: HTMLElement;
  public activeItem: Element | null = null;

  constructor() {
    this.created = false;
    this.busy = false;
    this.body = document.body;
  }

  public menuBtnHandler(): void {
    if (this.busy === true) {
      return;
    }

    if (this.body?.dataset.open === 'on') {
      this.close();
      this.body.dataset.menu = '';
      this.body.dataset.open = ActiveState.OFF;
    } else {
      this.open();
      this.body.dataset.menu = NavigationState.MOBILE;
      nodes.siteHeader?.classList.add(
        ComponentStates.STICKY,
        ComponentStates.TRANSITION,
        ComponentStates.VISIBLE
      );
      this.body.dataset.open = ActiveState.ON;
    }
  }

  public buildMenu(): void {
    const menuData = this.getTemplateDataset();
    const tpl = template(menuTpl);
    nodes.mobileMenu = document.createElement('menu');
    nodes.mobileMenu.classList.add('mobile-navigation', ComponentStates.OPEN);
    nodes.mobileMenu.innerHTML = tpl(menuData);

    nodes.siteHeader?.parentNode?.insertBefore(
      nodes.mobileMenu as HTMLElement,
      nodes.siteHeader.nextSibling
    );
    nodes.mobileLinkHolder = [
      ...(nodes.mobileMenu?.querySelectorAll(
        '.mobile-link-holder'
      ) as NodeListOf<HTMLElement>),
    ];

    nodes.mobileLinkHolder.forEach((mobileItem) => {
      mobileItem.addEventListener('click', this.clickHandler.bind(this));
    });

    if (
      nodes.actionsLinkHolder?.findIndex((e) => e.querySelector('.btn-search'))
    ) {
      const searchBox = nodes.siteHeader?.querySelector(
        '.header-search-box'
      ) as Node;
      nodes.mobileMenu?.prepend(searchBox.cloneNode(true));
    }

    nodes.specialButtons?.forEach((btnItem) =>
      nodes.mobileMenu
        ?.querySelector('.mobile-links ul')
        ?.appendChild(btnItem.cloneNode(true))
    );

    this.created = true;
  }

  public open() {
    this.busy = true;

    if (this.created === false) {
      this.buildMenu();
    } else {
      nodes.mobileMenu?.classList.add(ComponentStates.OPEN);
      this.activeItem?.classList.remove(ComponentStates.ACTIVE);
    }

    // timeout
    this.busy = false;
  }

  public close() {
    if (this.created === false) {
      return;
    }
    this.busy = true;
    nodes.mobileMenu?.classList.remove(ComponentStates.OPEN);
    this.activeItem?.classList.remove(ComponentStates.ACTIVE);
    setTimeout(() => {
      nodes.mobileMenu?.classList.remove(
        ComponentStates.SECOND,
        ComponentStates.FIRST
      );
    }, 301);
    this.busy = false;
  }

  public clickHandler(e: MouseEvent): void {
    const target = <HTMLElement>e.target;
    target.classList.toggle(ComponentStates.ACTIVE);
    if (this.activeItem !== target) {
      this.activeItem?.classList.remove(ComponentStates.ACTIVE);
    }
    this.activeItem = target;
  }

  public getTemplateDataset() {
    const templateMobileMenu = {
      menu: {},
      actions: {},
    };

    if (nodes.mobileActions) {
      templateMobileMenu.actions = nodes.mobileActions.map((node) => {
        // added for auth modal and similar events
        const dt = { ...node?.dataset };
        const dtFormatted: Dictionary<string> = {};

        Object.keys(dt).forEach((d) => {
          const newKey = d
            .split(/(?=[A-Z])/)
            .join('-')
            .toLowerCase()
            .trim();
          dtFormatted[newKey] = dt[d] || '';
        });

        return {
          title: node?.getAttribute('title'),
          link: node?.getAttribute('href'),
          icon: node?.querySelector('i')?.className,
          current: node?.getAttribute('data-current'),
          dataset: dtFormatted,
        };
      });
    }

    if (nodes.navigationLinkHolder) {
      templateMobileMenu.menu = nodes.navigationLinkHolder.map((node) => {
        const linkHolder = node.querySelector('.nav-item');
        const title = linkHolder?.getAttribute('title');
        const link = linkHolder?.getAttribute('href');
        const current = linkHolder?.getAttribute('data-current');
        const dropdown = node.querySelector('.dropdown') as HTMLElement;

        if (!dropdown) {
          return { title, link, current };
        }

        return {
          title,
          link,
          current,
          dropdown: dropdown.outerHTML,
        };
      });
    } else {
      return {};
    }

    return templateMobileMenu;
  }
}

export const navMobile = new MobileMenu();
