import { viewport } from '../modules/includeMedia';
import { GlobalEvents } from '../models/events';
import { Navigation } from '@/modules/navigation/navigation';
import { Footer } from '@/modules/footer/footer';
import { Broadcast } from '@/utilities/broadcast';

document.addEventListener('DOMContentLoaded', () => {

  // Attach header navigation
  const navElement: HTMLElement = document.querySelector(
    '.header'
  ) as HTMLElement;
  new Navigation(navElement);

  const footElement: HTMLElement = document.querySelector(
    '.footer'
  ) as HTMLElement;
  new Footer(footElement);

  // Start watching our breakpoints toggle.
  viewport.watch();

  Broadcast(window, GlobalEvents.DOM_READY);
});
