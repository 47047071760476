import { nodes } from './navigationNodes';
import { ActiveState } from '../../models/activeState';
import { ComponentStates } from '@/models/componentStates';
import { navMobile } from './navigationMobile';
import { siteSearch } from './navigationSearch';

class NavigationActions {
  public create() {
    nodes.navigationLinkHolder = [
      ...(nodes.siteHeader?.querySelectorAll(
        '.link-holder'
      ) as NodeListOf<HTMLElement>),
    ];
    nodes.actionsLinkHolder = [
      ...(nodes.siteHeader?.querySelectorAll(
        '.btn-header-icon'
      ) as NodeListOf<HTMLElement>),
    ];

    nodes.mobileActions = [
      ...(document.querySelectorAll(
        '.header-actions .mobile-item'
      ) as NodeListOf<HTMLElement>),
    ];

    nodes.navigationLinkHolder.forEach((navigationItem) => {
      navigationItem.addEventListener('mouseover', this.mouseenter.bind(this));
      navigationItem.addEventListener('mouseleave', this.mouseleave.bind(this));
      navigationItem.addEventListener('click', this.clickHandler.bind(this));
    });

    nodes.actionsLinkHolder.forEach((navigationItem) => {
      navigationItem.addEventListener('mouseover', this.mouseenter.bind(this));
      navigationItem.addEventListener('mouseleave', this.mouseleave.bind(this));
      navigationItem.addEventListener('click', this.clickHandler.bind(this));
    });

    // Special buttons (e.g. Donate button)
    nodes.specialButtons = [
      ...(nodes.siteHeader?.querySelectorAll(
        '.link-holder-btn:not(.link-holder)'
      ) as NodeListOf<HTMLElement>),
    ];
  }

  public mouseenter(e: MouseEvent): void {
    const target = <HTMLElement>e.currentTarget;

    if (target.classList.contains('link-holder')) {
      nodes.siteHeader?.classList.add(ComponentStates.ACTIVE);
    }

    if (target.classList.contains('has-dropdown')) {
      target.classList.add(ComponentStates.ANIMATING, ComponentStates.OPEN);
      target.dataset.open = ActiveState.ON;
    }
  }

  public mouseleave(e: MouseEvent): void {
    const target = <HTMLElement>e.target;

    nodes.siteHeader?.classList.remove(ComponentStates.ACTIVE);

    if (target.classList.contains('has-dropdown')) {
      target.classList.remove(ComponentStates.OPEN);
      target.dataset.open = ActiveState.OFF;
    }
  }

  public clickHandler(e: MouseEvent): void {
    const target = <HTMLElement>e.currentTarget;

    if (target.classList.contains('btn-search')) {
      siteSearch.action();
    }

    if (target.classList.contains('btn-hamburger')) {
      navMobile.menuBtnHandler();
      siteSearch.close();
    }
  }
}

export const navActions = new NavigationActions();
