import { DebouncedFunc, debounce } from 'lodash';
import { GlobalEvents } from '../models/events';

interface Breakpoints {
  [key: string]: BreakpointItem;
}

interface BreakpointItem {
  value: string;
  active: boolean;
}

interface BreakpointActive {
  name: string;
  value: number;
}

class ResponsiveSizes {
  public element: HTMLElement;
  public autoUpdate: boolean;
  public breakpoints: boolean;
  public pointsObject: Breakpoints;


  constructor() {
    this.element = document.body;
    this.autoUpdate = true;
    this.breakpoints = false;
    this.pointsObject = {};
  }

  public setElement(newElement: HTMLElement): void {
    this.element = newElement;
  }

  public setUpdateMode(updateMode: string) {
    if (updateMode === 'manual') {
      this.autoUpdate = false;
      this.readBreakpoints();
    }
  }

  private readBreakpoints() {
    if (
      window.getComputedStyle &&
      window.getComputedStyle(this.element, '::after').content !== ''
    ) {
      const data: string = window.getComputedStyle(
        this.element,
        '::after'
      ).content;

      try {
        this.pointsObject = JSON.parse(this.removeQuotes(data));
        this.breakpoints = true;
      } catch (err) {
        this.breakpoints = false;
      }
    } else {
      this.breakpoints = false;
    }
  }

  private isBreakpointActive(breakpoint: string) {
    if (this.autoUpdate) {
      this.readBreakpoints();
    }

    return (
      breakpoint in this.pointsObject && this.pointsObject[breakpoint].active
    );
  }

  private isBreakpointNotActive(breakpoint: string) {
    return !this.isBreakpointActive(breakpoint);
  }

  private getActiveBreakpoint() {
    if (this.autoUpdate) {
      this.readBreakpoints();
    }

    let largest: BreakpointActive = { name: '', value: 0 };

    for (const breakpoint in this.pointsObject) {
      if (breakpoint in this.pointsObject) {
        const breakpointValue = parseFloat(this.pointsObject[breakpoint].value);

        if (
          this.pointsObject[breakpoint].active &&
          breakpointValue > largest.value
        ) {
          largest = { name: breakpoint, value: breakpointValue };
        }
      }
    }

    return largest.name;
  }

  private getBreakpointValue(breakpoint: string, asNumber: number): number | string {
    if (this.autoUpdate) {
      this.readBreakpoints();
    }
    if (!this.breakpoints || !(breakpoint in this.pointsObject)) {
      return 0;
    }

    return asNumber
      ? parseFloat(this.pointsObject[breakpoint].value)
      : this.pointsObject[breakpoint].value;
  }

  private removeQuotes(string: string) {
    string = string
      .replace(/[']/g, '"')
      .replace(/\\|^[\s\S]{0,1}|[\s\S]$/g, '');

    return string;
  }

  public greaterThan = this.isBreakpointActive;
  public lessThan = this.isBreakpointNotActive;
  public getActive = this.getActiveBreakpoint;
  public getValue = this.getBreakpointValue;
  public update = this.readBreakpoints;

  private breakpointHandler() {
    document.dispatchEvent(
      new CustomEvent(GlobalEvents.BREAKPOINT_CHANGE, {
        bubbles: true,
        detail: viewport.getActive(),
      })
    );
  }

  private breakpointWatcher: DebouncedFunc<VoidFunc> = debounce(this.breakpointHandler, 200);

  public watch() {
    window.addEventListener(
      'resize',
      this.breakpointWatcher,
      false
    );

    // Fire off a 'resize' event on page load.
    window.dispatchEvent(new Event('resize'));
  }
}

export const viewport = new ResponsiveSizes();
