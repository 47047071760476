// This is a shared store between the navigation modules.
export class NavigationNodes {
  public actionsLinkHolder?: HTMLElement[];
  public hamburgerBtn?: Element;
  public mobileActions?: HTMLElement[];
  public mobileLinkHolder?: HTMLElement[];
  public mobileMenu?: HTMLElement;
  public navigationLinkHolder?: HTMLElement[];
  public searchMenu?: HTMLElement;
  public siteHeader?: HTMLElement;
  public specialButtons?: HTMLElement[];
}

export const nodes = new NavigationNodes();